.memories-container-bg{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999999999999999;
  background: #0000009e;
}
.memories-container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75%; 
    height: 80vh; /* Adjusted for modal content */
    z-index: 1000;
    display: flex;
    z-index: 99999;
    flex-direction: column;
    justify-content: center; /* Now centers items vertically */
    align-items: center; /* Keeps items centered horizontally */
    overflow-y: auto; /* Allows for vertical scrolling */
    overflow-x: hidden; /* Prevents horizontal scrolling */
    padding: 20px 0; /* Adds some padding top and bottom */
    background-color: rgba(255, 255, 255, 0); /* Optional: transparent background */
    border-radius: 10px; /* Optional: rounded corners for the modal */
  }
  
  /* The rest of your styles for .memory-memoryItem, .memoryItem-wrapper, .memory-blueContainer, .memory-redCross, and .memory-text can remain the same. */
  

  .memories-container .modal-background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; /* Keep the background image behind all content */
  }
  
  .memories-container .modal-close-button {
    position: absolute;
    top: 0vh; /* Half of the close button's height for partial visibility outside the modal */
    right: 0vh; /* Half of the close button's width for partial visibility outside the modal */
    width: 20vh; /* Adjust the width as needed */
    height: 20vh; /* Adjust the height as needed */
    cursor: pointer;
    z-index: 10; /* Ensure the close button is above all other modal content */
  }

/* The rest of your styles for .memoryItem-wrapper, .memory-blueContainer, .memory-redCross, and .memory-text can remain the same. */

  
  .memoryItem-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .memory-blueContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .memory-redCross {
    position: absolute;
    top: 0;
    z-index: 9999;
    left: -5vh; /* Adjusted from -1vh to 10px for consistency */
    width: 15vh;
    height: 15vh;
    cursor: pointer; /* Indicates the element is clickable */
      /* Remove tap highlight color */
  -webkit-tap-highlight-color: transparent;

  /* Remove focus outline */
  outline: none;

  /* Optional: Remove user select to prevent text selection */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  }
  
  .memory-text {
    font-family: 'Grisly-Beast';
    position: absolute;
    font-size:6vh; /* Adjusted for better readability */
    width: 80%;

    color: #4c1a85;
    text-align: center;
    z-index: 5; /* Ensures text is visible above the background */
  }
  
  .modal-close-button {
    position: absolute;
    top: -10px; /* Positioned partially above the modal */
    right: -10px; /* Positioned partially to the right of the modal */
    width: 10vh; /* Adjust the width as needed */
    height: 10vh; /* Adjust the height as needed */
    cursor: pointer; /* Indicates the element is clickable */
    z-index: 10; /* Ensures the button is above other modal content */
          /* Remove tap highlight color */
  -webkit-tap-highlight-color: transparent;

  /* Remove focus outline */
  outline: none;

  /* Optional: Remove user select to prevent text selection */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  }

  .modal2-close-button {
    position: absolute;
    top: 50px; /* Positioned partially above the modal */
    right: -10px; /* Positioned partially to the right of the modal */
    width: 20vh; /* Adjust the width as needed */
    height: 20vh; /* Adjust the height as needed */
    cursor: pointer; /* Indicates the element is clickable */
    z-index: 10; /* Ensures the button is above other modal content */
          /* Remove tap highlight color */
  -webkit-tap-highlight-color: transparent;

  /* Remove focus outline */
  outline: none;

  /* Optional: Remove user select to prevent text selection */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  }
  
  .memories-list {
    transform: translateY(5vh);
    width: 100%; /* Adjust width as needed */
    height: 70%; /* Container height - often set to a specific value like 80vh */
    max-height: 80vh; /* Optional: limits the modal's total height */
    flex-direction: column;
    align-items: center;
    overflow-y: auto; /* Allows scrolling */

    box-sizing: border-box; /* Include padding and border in the height */
  }
  
  .memory-memoryItem {
    height: 35%; /* Each item is 33% of the parent's height */
    width: 70%; /* Adjust width as needed */
    margin: 0 auto; /* Center horizontally */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  
  /* Style adjustments for MemoryItem if necessary */
  .memory-item {
    margin-bottom: 10px; /* space between items */
  }

  .memories-list::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
  }
  
  .memories-list::-webkit-scrollbar-track {
    background: transparent; /* Transparent track */
  }
  
  .memories-list::-webkit-scrollbar-thumb {
    background: red; /* Orange color thumb */
    border-radius: 20px; /* Rounded edges for a capsule-like shape */
    border: 3px solid transparent; /* Makes it more capsule-like */
    background-clip: content-box; /* Avoids color bleeding into the borders */
  }
  
  .memories-list::-webkit-scrollbar-thumb:hover {
    background: darkorange; /* Slightly darker orange on hover for visual feedback */
  }

  .no-memories-text {
    padding-top: 15vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Grisly-Beast';
    font-size: 5vh;
    opacity: 0.7;
    color: black;
    width: 100%;
    height: 100%;
}
