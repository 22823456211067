@font-face {
  font-family: f;
  src: url('../img/LilitaOne-Regular.ttf');
}
.alien-action-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 55%;
    margin: auto; /* Centers the bar horizontally */
    z-index: 99999;
    overflow-x: auto;
    white-space: nowrap;
    bottom: 2vh;
    padding: 1vw;
    display: flex;
    justify-content: start; /* Align items to the start of the flex container */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    border-radius: 5vh; /* Adjust as needed for desired curvature */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Optional: Adds shadow for depth */
  }
  
  
  .alien-action-bar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
  
  .alien-action-button {
    position: relative;
    display: inline-block;
    background: transparent;
    border: none;
    padding: 0.2vh;
    margin-right: 1vw;
    cursor: pointer;
    transition: transform 0.2s ease; /* Smooth transition for press effect */

    -webkit-tap-highlight-color: transparent; /* Remove tap highlight color */
    border-radius: 5px; /* Optional: Makes individual buttons also slightly rounded */
  }
  .alien-action-button p{
    color:white;
    font-family: f;
    font-size: 3vb;
    position: absolute;
    z-index: 2;
    /* height: 0; */
    margin: 0;
    top:0;
    width: 100%;
    text-align: center;
    top:100%;
    transform: translateY(-70%);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.188), 3px 3px rgba(0, 0, 0, 0.879);
    text-transform: uppercase;
    text-wrap: wrap;
    line-height: 2.5vb;
    height: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    opacity: .9;
  }
  .alien-action-button img {
    height: 15vh;
    width: auto;
  }
  
  .alien-action-button-pressed {
    transition: .3s;
    transform: scale(0.80); /* Slightly reduce the size to simulate pressing */
  }