.splash-screen {
  display: flex;
  flex-direction: column;
  justify-content: center; /* This will vertically center the items in the available space */
  align-items: center;
  position: relative;
  height: 100vh; /* Full height of the viewport */
  overflow-y: hidden; /* Hide overflow on y-axis */
  background-image: url('../img/assets/blue-bg.jpg');
  background-size: cover; /* Cover the entire screen with the image */
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Do not repeat the image */
}

.moon-image {
  z-index: 501;
  position: absolute; /* Position the moon absolutely within the splash-screen */
  bottom: 0; /* Anchor it to the bottom */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%) translateY(5%); /* Adjust for the moon's width to ensure it's centered */
  width: 100%; /* Adjust the size as needed */
  height: auto; /* Maintain aspect ratio */
}

.alien-button-scroll-container {

  top: 30vh; /* Starts just below the navbar */
  width: 20vw; /* Adjust width as needed */
  height: 70vh; /* Takes up the rest of the viewport height */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start; /* Aligns content to start from the top */
  overflow-y: auto; /* Enables vertical scrolling within the container */
  z-index: 99999;
}

.settings-button img,.alienSnapGame-button img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* or use 'contain' based on your preference */
}
#gameController{
  transform: translateY(-33.7vh) translateX(1vh);

}

@keyframes logoAnim {
  0% {
    transform: scale(1) rotate(-5deg);
  }
  50% {
    transform: scale(0.96) rotate(0deg);
  }
  100% {
    transform: scale(1) rotate(-5deg);
  }
}
/* Styles for the buttons container */
.alien-button-container {
  display: flex;
  flex-direction: column;
  z-index: 2000;
  align-items: flex-end;
  gap: 20px;
  padding: 0;
  padding-bottom: 5vh; /* Adjust the value as needed */
  margin: 0;
  position: relative;
}

.alien-button-scroll-container::-webkit-scrollbar {
  display: none; /* Hides scrollbar for Webkit browsers like Chrome and Safari */
}

.alien-button-scroll-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}


.alien-button:active {
  transform: scale(0.9);
}


.alien-button {
  padding: 2vh 5vh; /* Using viewport units for scalable padding */
  border: none;
  border-radius: 25px; /* Rounded corners for buttons */
  cursor: pointer;
  font-family: 'LilitaOne-Regular', sans-serif;
  font-size: 5.5vh; /* Scalable font size */
  transition: transform 0.1s ease; /* Animation for the transform property */
  min-height: 13vh;
  min-width: 30vh;
  z-index: 999;
  width: auto;
  text-align: center; /* Ensures text is centered if button width exceeds text width */
  /* Adjust the values as needed to maintain the aspect of the buttons */
  background: transparent;
}

.generic-button {
  background-size: cover;
  z-index: 999;
  background-position: center;
  background-repeat: no-repeat;
}

.poke-button {
  background-image: url('../img/assets/buttons/normal/poke.png');
}

.joke-button {
  background-image: url('../img/assets/buttons/normal/joke.png');
}

.eat-button {
  background-image: url('../img/assets/buttons/normal/eat.png');
}

.sleep-button {
  background-image: url('../img/assets/buttons/normal/sleep.png');
}


.sleep-button {
  background-image: url('../img/assets/buttons/normal/sleep.png');

}


.moonwalk-button-sub {
  background-image: url('../img/assets/buttons/locked/moonwalk.png');

}



.dance-button-sub {
  background-image: url('../img/assets/buttons/locked/dance.png');

}

.juggle-button-sub {
  background-image: url('../img/assets/buttons/locked/juggle.png');

}

.jump-button-sub {
  background-image: url('../img/assets/buttons/locked/jump.png');

}

.walk-button-sub {
  background-image: url('../img/assets/buttons/locked/walk.png');

}

.meditate-button-sub {
  background-image: url('../img/assets/buttons/locked/meditate.png');

}

.poke-button-sub {
  background-image: url('../img/assets/buttons/locked/poke.png');

}

.poop-button-sub {
  background-image: url('../img/assets/buttons/locked/poop.png');

}

.sing-button-sub {
  background-image: url('../img/assets/buttons/locked/sing.png');

}

.tango-button-sub {
  background-image: url('../img/assets/buttons/locked/tango.png');
}

.alien-container{
  max-width: 50%; /* Reduced size of the alien image */
  height: 80vh; /* Keep the aspect ratio intact */
  top: 26vh;
  z-index: 999;
  position: absolute;
}
.centered-gif {
  width: 100%;
  height: 100%;
  transition: transform 1s ease; /* Smooth transition for the transform and background color */
  z-index: 999;
  position: relative;
}

.talking-gif {
  transform: translateX(7px); /* Move the GIF 5px to the right */
}

.alien-effects{
  position: absolute;
  z-index: 9;
}
.alien-effects div{
  height: auto;
  width: auto;
  transform: translate(16vw,48vh) scale(.1);
  position: absolute;
}
.alien-effects div img{
  height: 100px;
  animation: logoAnim 1.5s infinite;
}

.poke-animation {
  animation: poke 1s linear;
}

@keyframes poke {
  0% { transform: scale(1) rotate(0deg); }
  50% { transform: scale(0) rotate(360deg); }
  100% { transform: scale(1) rotate(720deg); }
}


.day-container {
  position: absolute;
  top: 5vh;
  left: 5vh;
  z-index: 1000;
  margin: 20px;
  color: white;
  /* Existing styles omitted for brevity */
}

.day-number {
  font-size: 5vh; /* Adjust size as needed */
  font-family: 'Grisly-Beast';
  text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.5); /* X-offset, Y-offset, blur-radius, color */
  text-align: center;
  /* Add other styling such as font-family, etc., as needed */
  animation: float 2s ease-in-out infinite; /* Apply the float animation */
}

.day-image {
  width: 20vh; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Ensure it starts on a new line below the text */
  position: relative; /* Relative positioning within the container */
  top: -5vh; /* Move the image up by 3vh; adjust as needed */
  animation: float 2s ease-in-out infinite; /* Apply the float animation */
  z-index: 1000; /* Ensures the spaceship is above the gradient shape */
}

@keyframes appearAndMoveDown {
  from {
    opacity: 0;
    transform: translate(-50%, -90%); /* Start higher and more transparent */
  }
  to {
    opacity: 1;
    transform: translate(-50%, -80%); /* End position, fully visible */
  }
}


.gradient-shape {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -80%);
  width: 22vh; /* Adjust based on your content */
  height: 12vh; /* Adjust based on your content */
  background: linear-gradient(to top, rgba(255, 162, 0, 0), #ffa200); /* Gradient transition */
  color: white; /* Text color */
  text-align: center;
  line-height: 10vh; /* Adjusted to match height for vertical centering */
  z-index: 1; /* Lower than .day-image to ensure it's behind */
  opacity: 0; /* Start fully transparent */
  animation-fill-mode: forwards; /* Ensures it retains the style from the last keyframe */

  /* Define a trapezium shape */
  clip-path: polygon(25% 0%, 75% 0%, 100% 100%, 0% 100%);
}


.text-content {
  font-family: 'Grisly-Beast';
  position: absolute;
  top: 15%; /* Start at 50%, then adjust as needed */
  width: 100%; /* Ensure it spans the width of .gradient-shape */
  text-align: center; /* Keep the text centered */
  text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.5); /* X-offset, Y-offset, blur-radius, color */
  font-size: 2.3vh;
}

.tight-br {
  display: block;
  margin-top: -17vh; /* Pulls the following content closer */
}

@keyframes float {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-1vh); /* This will move the image up by 3vh and then back */
  }
}

.happy-meter-title {
  font-family: 'Grisly-Beast';
  font-size: 4VH; /* Adjust size as needed */
  text-align: center; /* Center the text */
  color: white; /* Adjust color as needed */
}

.outer-happy {
  position: absolute;
  top: 4vh; /* Adjusted to be below the navbar */
  width: 40vw;
  z-index: 3;
  display: flex;
  flex-direction: column; /* Change to column for vertical stacking */
  justify-content: center; /* Center children horizontally */
  align-items: center; /* Center children vertically */
}

.happy-bar {
  width: 25vw;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.content-container {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  height: 100%;
  align-items: flex-start;
}

.happy-meter-container {
  position: relative; /* Changed from absolute to relative for correct stacking */
  height: 8vh;
  width: 98%;
  margin: 1vh 0;
  background: linear-gradient(to bottom, #5599bf, #66bcd2);
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  border-radius: 2vh;
}



.happy-meter-container-props {
  width: 100%;
}

.happy-meter-stripes {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: width 2s ease; /* Smooth transition for width changes */
  background: repeating-linear-gradient(
      -45deg, /* Angle of the stripes */
      #fd8514, /* Color of the stripes */
      #fd8514 30px, /* Width of each stripe, increased from 10px to 20px */
      transparent 30px, /* Beginning of transparent gap, same as stripe width */
      transparent 60px /* Width of each gap, increased from 20px to 40px */
  );
  border-radius: 1vh 10vh 10vh 1vh; /* top-left, top-right, bottom-right, bottom-left */
  z-index: 9; /* Above the fill but below interactive elements */
}

.happy-meter-fill {
  background-color: #ffc500;
  border-radius: 1vh 10vh 10vh 1vh; /* top-left, top-right, bottom-right, bottom-left */
  width: 100%;
  height: 100%; /* Fill should occupy the full height of its container */
  transition: width 2s ease; /* Smooth transition for width changes */
  position: relative; /* Ensure it's above the ::after pseudo-element */
  z-index: 2; /* Above the ::after pseudo-element */
}

.happy-face-image {
  width: 4vw;
  height: auto;
  padding: 1vh;
  position: relative;
  object-fit: contain;
}


.disco-ball-container {
  position: absolute;
  top: -20vh; /* Final position */
  left: 50%;
  z-index: 1000;
  transform: translateX(-50%);
}

.notification-text-container {
  position: absolute;
  top: 17vh; /* Final position */
  font-size: 7vh;
  text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.5); /* X-offset, Y-offset, blur-radius, color */
  z-index: 100000;
  font-family: 'Grisly-Beast';
  opacity: 0;
  color: #FFD700; /* Gold color */
  text-align: center; /* Center the text */
}


.animate-popup {
  animation: popUpNotification 2s ease forwards;
  visibility: visible; /* Make it visible when this class is added */
  opacity: 1; /* Ensure it's fully visible at the start of the animation */
}

.animate-pop-down {
  animation: popDownNotification 2s ease forwards;
  visibility: visible; /* Make it visible when this class is added */
  opacity: 1; /* Ensure it's fully visible at the start of the animation */
}



@keyframes popUpNotification {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
  30% { transform: scale(1) rotate(-10deg); }
  35% { transform: scale(1) rotate(10deg); }
  40% { transform: scale(1) rotate(-10deg); }
  45% { transform: scale(1) rotate(10deg); }
  50% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
}

@keyframes popDownNotification {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}


.disco-ball-container img {
  width: 20vh; 
  height: auto;
  z-index: 1000;
}


.animate-coming-down {
  animation: comingDown 2s ease forwards;
  visibility: visible; /* Make it visible when this class is added */
  opacity: 1; /* Ensure it's fully visible at the start of the animation */
}


.animate-going-up {
  animation: movingUp 2s ease forwards;
}

@keyframes comingDown {
  from {
    top: -20vh;
    opacity: 0;
  }
  to {
    top: 0vh;
    opacity: 1;
  }
}

@keyframes movingUp {
  from {
    top: 0vh;
  }
  to {
    top: -20vh;
  }
}


@keyframes shrinkToZero {
  from {
    transform: translateX(-50%) scale(1); /* Original size */
    opacity: 1;
  }
  to {
    transform: translateX(-50%) scale(0); /* Shrink to zero */
    opacity: 0;
  }
}


.happy-meter-overlay {
   position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Full width of the container */
    height: 100%; /* Full height of the container */
    z-index: 10; /* Above the fill and stripes */
    object-fit: fill; /* Preserve aspect ratio while covering entire container */
}

@keyframes shake {
  0% { transform: translate(2px, 0); }
  10% { transform: translate(-2px, 0); }
  20% { transform: translate(2px, 0); }
  30% { transform: translate(-2px, 0); }
  40% { transform: translate(2px, 0); }
  50% { transform: translate(-2px, 0); }
  60% { transform: translate(2px, 0); }
  70% { transform: translate(-2px, 0); }
  80% { transform: translate(2px, 0); }
  90% { transform: translate(-2px, 0); }
  100% { transform: translate(2px, 0); }
}

@keyframes miniPulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

.miniPulse {
  animation: 
    miniPulse 1s infinite;  /* Pulse effect */
}

.shake {
  animation: shake 2s;
  animation-iteration-count: infinite;
}

.logo-container {
  display: flex;
  z-index: 1000011;
  justify-content: center;
  align-items: center;
}

@keyframes logoPulse {
  0% {
      transform: scale(0.8);
  }
  50% {
      transform: scale(1);
  }
  100% {
      transform: scale(0.8);
  }
}

.gameLogo {
  width: 50vh; /* Adjust based on your logo's size */
  z-index: 999;
  max-width: 50vh;
  /* animation: logoPulse 2s infinite ease-in-out; */
  height: auto;
}

.poop-container {
  position: fixed;

  left: 50%;
  bottom: 5vh;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 50%; /* Adjust based on your design needs */
  max-width: 200px; /* Optional: Limit the container's max width */
  z-index: 99999; /* Ensure it's above other page content */
}

.poop {
  margin: 5px 0; /* Adjust based on your design needs */
  width: 10vh; /* Poop width relative to viewport height */
  height: 10vh; /* Poop height relative to viewport height */
  animation: float 3s ease-in-out infinite;
  z-index: 99999; /* This can be omitted if the .poop-container already ensures proper stacking */
}

@keyframes flyAwaySpin {
  0% {
    transform: translate(0, 0) scale(1) rotate(0deg);
    opacity: 1;
  }
  100% {
    transform: translate(-50vw, -100vh) scale(0) rotate(360deg);
    opacity: 0;
  }
}

.fly-away {
  animation: flyAwaySpin 2s forwards;
}

@keyframes floatUpwards {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px); /* Adjust the value based on the desired float height */
  }
}




.settings-button:active {
  transform: scale(0.6); /* Slightly reduce the size to give a press effect */
}



.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}


.poop,
.settings-button,
.alienSnapGame-button,
.generic-button,
.left-settings-button
.memory-redCross
.memory-memoryItem
{
  /* Remove tap highlight color */
  -webkit-tap-highlight-color: transparent;

  /* Remove focus outline */
  outline: none;

  /* Optional: Remove user select to prevent text selection */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.phrases-container {
  display: flex;
  flex-direction: column;
}

.phrase-row {
  display: flex;
  justify-content: space-between; /* Adjust as needed */
  margin-bottom: 10px; /* Spacing between rows */
}

.phrase {
  flex-basis: calc(50% - 5px); /* Adjust the spacing based on the parent container's size */
  text-align: center; /* Center the text within each phrase */
  /* Add padding, background, etc., as needed */
}


.commands-text {
  font-family: 'Grisly-Beast';
  text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.5); /* X-offset, Y-offset, blur-radius, color */
  top: 20vh;
  font-size: 2.5vh;
  width: 20vw;
  color: white;
  text-align: center;
  z-index: '99999'
}

.unlimited-button-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 18vh;
  bottom: 0;
  display: flex;
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  text-align: center; /* Ensure text inside divs is centered */
}

.settings-container {
  z-index: 10000;
  height: 100%; /* This sets the height of the container */
  width: 100%; /* This sets the width of the container to full width */
  position: relative; /* Change to relative to position children absolutely within it */

  left: 0; /* Align to the left edge of the viewport */
  right: 0; /* Align to the right edge of the viewport */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.modal-background-image {
  max-width: 100%; /* Ensures the image is not wider than its container */
  max-height: 100%; /* Ensures the image is not taller than its container */
  width: 95%;
  height: 95%;
  object-fit: contain; /* Ensures the full image is visible, but may introduce letterboxing */
}

.upsellText {
  color: white;
  padding: 1vh;
  font-size: 5vh;
  font-family: 'Grisly-Beast';
  text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.5); /* X-offset, Y-offset, blur-radius, color */
}
.upsell-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Space between buttons */
}

.button-wrapper {
  position: relative;
  display: inline-block; /* Contains the button and text */
}

.upsell-button-container img {
  display: block; /* Ensures img takes up the entire space of .button-wrapper */
  width: auto; /* Maintain aspect ratio */
  max-height: 15vh; /* Adjust the maximum height as needed */
  width: auto; /* Maintain aspect ratio */
  cursor: pointer;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  font-size: 6vh;
  text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.5); /* X-offset, Y-offset, blur-radius, color */
  padding-bottom: 2vh;
  transform: translate(-50%, -50%);
  color: white; /* Adjust color for visibility */
  font-weight: bold;
  pointer-events: none; /* Allows clicks to pass through to the button */
}

.option-button {
  width: 20%;
}

 
.option-button:active {
  transform: scale(0.9);
}

@keyframes fillBarPulse {
  0% {
    transform: scale(1);
  }
  10% {
    transform: scale(1.2); /* Quickly expand to a bit larger than the target */
  }
  30% {
    transform: scale(0.9); /* Contract below the initial size */
  }
  50% {
    transform: scale(1.05); /* Expand, but less so */
  }
  70% {
    transform: scale(0.95); /* Contract again, but closer to 1 */
  }
  90% {
    transform: scale(1.02); /* Final expansion before settling */
  }
  100% {
    transform: scale(1); /* Return to original size */
  }
}

.outer-happy-pulse {
  animation: fillBarPulse 2s ease-in-out;
}



.game-over {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  z-index: 10000;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 50% opacity for the background */
  top: 20vh;
  overflow: hidden;
  position: absolute;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#loading-wrapper {
  position: fixed; /* Use fixed positioning to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Viewport width */
  height: 100vh; /* Viewport height */
  z-index: 9999; /* High z-index to ensure it's on top of other content */
}

.win-coins {
  transform: scale(0.9);
  position: absolute;
  right: 3vh;
  bottom: 7.5vh; /* Optionally, set top position if needed */
  height: 100vh;
  width: auto; /* You might need to adjust this as per your requirements */
  color: white; /* White text color */
  z-index: 99999; /* Ensure the log view is on top of other elements */
  overflow: hidden; /* This will hide anything that overflows this container */
}

.capsule {
  width: 2vh; /* Capsule width */
  height: 5vh; /* Capsule height */
  background-color: #FFD700; /* Capsule color */
  border-radius: 10px; /* Capsule rounded corners */
  position: absolute; /* Position capsule absolutely within the scroll container */
  right: -2vh; /* Align capsule to the far right */
  z-index: 2100; /* Ensure capsule is above the button container but below other potential overlays */
}

#left-screen-settings-container {
  position: fixed;
  bottom: 1vh; /* Adjusted to position the container from the top */
  left: 15vh; /* Centers the container relative to the viewport's width */
  transform: translateX(-50%); /* Shifts the container left by half its width to truly center it */
  z-index: 10000; /* Ensure the container and its children are positioned above other content */
  display: flex;
  flex-direction: row;
  align-items: center; /* Center-align children */
}

#screen-settings-container {
  position: fixed;
  top: 20px; /* Adjusted to position the container from the top */
  left: 25%; /* Centers the container relative to the viewport's width */
  transform: translateX(-50%); /* Shifts the container left by half its width to truly center it */
  z-index: 10000; /* Ensure the container and its children are positioned above other content */
  display: flex;
  flex-direction: row;
  align-items: center; /* Center-align children */
}


#settings-label {
  position: fixed; /* Relative to the container */
  font-size: 5vh;
  left: 7vh;
  top: 6vh;
  width: 10vh;
  z-index: -500; /* Ensure the label is above the button */
  font-family: 'Grisly-Beast';
  color: white; /* Optional: change text color for visibility */
  border-radius: 10px; /* Optional: rounded corners for the label */
  padding: 3vh; /* Optional: padding around the text */
  margin-bottom: 10px; /* Space between label and button */
  text-align: center; /* Center the text within the label */
}

.settings-button,.alienSnapGame-button {
  background: transparent;
  border: none;
  cursor: pointer;
  /* transition: transform 0.1s ease, background-color 0.1s ease; */
  border-radius: 50%;
  width: 15vh;
  height: 15vh;
  padding: 1vh;
  transition: transform 0.3s ease-in-out;
  /* Removed positioning here since it's handled by the container */
}

.settings-button.right, .alienSnapGame-button.right {
  position: fixed;  
  bottom: 10px;    
  right: 10px;    
  z-index: 9999;
  width: 22vh;
  height: 22vh;
}

.settings-button.left {
  transform: translateY(10%);
  width: 22vh;
  height: 22vh;
}

#leaderboard-btn{
  position: absolute;
  margin-top: -37vh
  /* transform: translateY(-100px); */
}

.left-settings-button {
  background: transparent;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  border-radius: 50%;
  width: 10vh;
  height: 10vh;
  padding: 1vh;
  /* Removed positioning here since it's handled by the container */
}

.left-settings-button:active {
  transform: scale(0.9);
}

.game-over-container {
  position: relative; /* Creates a new stacking context */
  display: flex;
  z-index: 99999;
  justify-content: center; /* Center horizontally in the container */
  align-items: center; /* Center vertically in the container */
}

.game-over-text {
  position: absolute; /* Position the text absolutely relative to its parent */
  top: 50%;
  left: 50%;
  color: white;
  font-size: 6vh;
  padding-bottom: 1vh;
  font-family: 'Grisly-Beast';
  text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.5); /* X-offset, Y-offset, blur-radius, color */
  padding-bottom: 2vh;
  transform: translate(-50%, -50%); /* Offsets the text to be centered on the button */
  /* No z-index necessary if it's not about stacking order but overlaying */
}

.unlimited-button {
  width: 50%; /* Set the button width to 70% of its parent */
  /* Center the button if it's an inline element or inline-block */
  /* No need to adjust the position of the button itself since its container is flex */
}


@keyframes settings-pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.4);
      }
  100% {
      transform: scale(1);
  }
}


@keyframes moveLeftRightBackAndMirror {
  0%, 100% {
    transform: translateX(0) scaleX(1); /* Starting and ending position, not mirrored */
  }
  25% {
    transform: translateX(-25vh) scaleX(1); /* Move left without flip */
  }
  /* Instant flip to mirrored */
  26% {
    transform: translateX(-25vh) scaleX(-1); /* Mirrored */
  }
  50% {
    transform:  translateX(25vh) scaleX(-1); /* Continue right, mirrored */
  }
  /* Flip back to normal */
  51% {
    transform:  translateX(25vh) scaleX(1); /* Normal */
  }
  75% {
    transform: translateX(0) scaleX(1); /* Back to center, normal */
  }
}


.animated-gif {
  animation: moveLeftRightBackAndMirror 7.5s ease-in-out 1 forwards
}


/* Additional CSS */
.flyDownAnimation {
  animation: flyDown 1.5s forwards;
}

.flyUpAnimation {
  animation: flyUp 1.5s forwards;
}


@keyframes flyDown {
  from {
    top: 5vh;
    left: 5vh;
    transform: scale(1); /* Starting at normal size */
  }
  to {
    top: 40vh;
    left: 30vh;
    transform: scale(2.5); /* Scaling up to 3 times the size */
  }
}

@keyframes flyUp {
  from {
    top: 40vh;
    left: 30vh;
    transform: scale(2.5); /* Starting from 3 times the size */
  }
  to {
    top: 5vh;
    left: 5vh;
    transform: scale(1); /* Scaling back down to normal size */
  }
}

@keyframes disappearAndMoveUp {
  from {
    opacity: 1;
    transform: translate(-50%, -80%); /* Starting position, fully visible */
  }
  to {
    opacity: 0;
    transform: translate(-50%, -100%); /* Moves up and fades out */
  }
}

.memories-gif {
  width: 100px;
  height: 100px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0; 
}

@keyframes flyToBottomRight {
  0% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(1);
  }
  20% {
      opacity: 1; 
  }
  100% {
      top: 100%;
      left: 100%;
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
  }
}


.alienWinGameLottie{
  position: absolute;
  /* background: #000; */
  z-index: 9999999999999;
}

#jukebox-btn{
  position: absolute;
  margin-top: -37vh;
  top: 92vh;
  /* background: #000; */
  animation: logoAnim 1.5s infinite;
}


#jukebox-btn img{
  max-height: 100%;
  width: 60%;
}