.modal-container-bg{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 999999999999999;
    background: #0000009e;
}
.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-height: 480px) and (max-width: 960px) {
    .modal-container {
        width: 55%;
    }
}

.modal-background {
    width: 100%;
    height: auto;
    display: block;
    
}

.icons-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 90%; /* Make sure it takes most of the modal width */
    position: absolute;
    top: 50%;
    transform: translateY(-25%);
}


@keyframes push-animation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}

.icon {
    cursor: pointer;
    transition: transform 0.5s ease;
    width: 33%; /* Increased size for larger icons */
    height: auto;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-focus-ring-color: transparent;
    box-shadow: none;
    position: relative;
}

.icon.push {
    animation: push-animation 1s forwards;
}