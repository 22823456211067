.text-box {
    width: 80%;
    padding: 20px 10px; /* Adjusted to add padding on the top and bottom, and on the sides */
    padding-left: 30px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-height: 5vh;
    font-size: 2vh; /* Adjusted for readability */
    word-wrap: break-word;
    margin-bottom: 20px;
    border: 2px solid rgba(137, 207, 240, 0.5); /* Light blue outline with opacity */
    position: relative;
  }

  .text-box.player {
    border: 2px solid orange; /* Light blue outline with opacity */
  }
  
  .user-icon {
    position: absolute;
    top: -15px; /* Adjust as needed to overlap the corner */
    left: -15px; /* Adjust as needed to overlap the corner */
    width: 7vh; /* Adjust size as needed */
    height: auto;
  }
  
  
  .alien-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 5px; /* Adjust space between header and main message */
  }
  
  .alien-prefix {
    font-weight: bold;
    font-size: 3vh; /* Ensures visibility */
    color: white; /* Adjust color as needed */
  }
  
  .message-content {
    width: 100%;
    text-align: left; /* Ensures text alignment */
    font-size: 3vh; /* Adjusted for main message readability */
    margin-top:12px; /* Creates space below the header, adjust as needed */
  }
  