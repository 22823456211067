.text-box-list {
  position: absolute;
  top: 20px; /* Adjust as needed */
  right: 20px; /* Positions the list on the right side, adjust as needed */
  display: flex;
  width: 30%;
  z-index: 9999;
  font-family: 'Grisly-Beast';
  flex-direction: column;

  gap: 20px; /* Adjust gap between items as needed */
  align-items: flex-end; /* Aligns items to the start, making it work well with right alignment */
}

.text-box-list > div {
  width: 100%; /* Ensures alignment with parent flex settings */
}

.fade-out {
  transition: opacity 1s ease-out;
  opacity: 0;
}
