
/* General Reset and Box Model */
.falling-stars-container {
    margin: 0;
    padding: 0;
    position: absolute;
    box-sizing: border-box;
    height: 100vh;
    z-index: 1;
    width: 100%;

    min-height: 100vh;
    overflow: hidden;
  }
  
  .star {
    position: absolute;
    width: 10px; /* Or any size */
    height: 10px; /* Or any size */
    background: white;
    border-radius: 50%; /* Makes it a circle */
    box-shadow: 0 0 20px white; /* Optional: Adds a glow effect */
    animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
    0% {
        transform: scale(0.8);
        opacity: 0.5;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(0.8);
        opacity: 0.5;
    }
}