.App {
  text-align: center;
}

.app-title {
  font-family: 'LilitaOne-Regular', sans-serif;
  color: #333;
}


body {
  font-family: 'LilitaOne-Regular', sans-serif;
  overflow-y: hidden; /* Hide overflow on y-axis */
}

@font-face {
  font-family: 'LilitaOne-Regular';
  src: url('../../public/LilitaOne-Regular.ttf') format('truetype');
  font-display: swap;
}


@font-face {
  font-family: 'Grisly-Beast';
  src: url('../../public/Grisly-Beast.ttf') format('truetype');
  font-display: swap;
}

body {
  font-family: 'LilitaOne-Regular', sans-serif;
}
