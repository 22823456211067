.loading-screen {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999; /* Adjust z-index as needed */
  }
  
  .starry-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1; /* Ensure the background stays behind other elements */
  }
  
  .star-image {
    position: absolute;
    top: calc(50% - 3vh); /* Adjust positioning based on the size of the star image */
    left: -7.5vh; /* Initial position off the left side of the container */
    width: 6vh; /* Set the width of the star image */
    height: auto; /* Maintain aspect ratio */
    z-index: 2; /* Ensure the star is above the background */
  }
  @keyframes moveStar {
    0% {
      transform: translateX(-40%) translateY(50vh) rotate(0deg); /* Start position: off the left side */
    }
    100% {
      transform: translateX(100vw) translateY(-70vh) rotate(1080deg); /* End position: off the right side */
    }
  }
  